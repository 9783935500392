import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Blazon from '../assets/blazon-small.svg';
import navigationLinks from '../data/navigationLinks';

const Header = ({ toggleMenu }) => {
    const location = useLocation(); // Get the current location object

    // Check if the current path is the main page's path
    // Assuming the main page's path is "/", adjust if it's different
    const isMainPage = location.pathname === "/";

    return (
        <header>
            <div className="header-left">
                {!isMainPage && ( // Hide blazon only if not on the main page
                    <div className='blazon'>
                        <img src={Blazon} alt="Virgin Islands Athletes Commission Blazon" />
                    </div>
                )}
                <div>
                    VIRGIN&nbsp;ISLANDS<br />
                    ATHLETES'&nbsp;Commission
                </div>
            </div>

            <div className='nav-mobile' onClick={toggleMenu}>
                [MENU]
            </div>

            <nav>
                <ul>
                    {navigationLinks.map(({ path, name }) => (
                        <li key={path}>
                            <NavLink to={path} className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 10" aria-hidden="true" focusable="false">
                                    <path className="st0" d="M6.5,9.5L5.8,8.7l3.2-3.2H0.8V4.5h8.2L5.8,1.3l0.8-0.8L11,5L6.5,9.5z" />
                                </svg>
                                {name}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </nav>
        </header>
    );
}

export default Header;
