// Function to import a single image
export const importImage = (project, imageName) => {
    return import(`../assets/images/mainpage/${imageName}`)
      .then(image => image.default)
      .catch(() => console.log("Error loading image"));
  };
  
  // Function to shuffle images
  export const shuffleArray = (array) => {
    let shuffled = array.slice(); // Create a copy to avoid mutating the original array
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };
  
  // Array of image names
  export const imageNames = [
    'cycling.jpg', 'archery.jpg', 'basketball.jpg', 'boxing.jpg',
    'fencing.jpg', 'golf.jpg', 'judo.jpg', 'sailing.jpg',
    'shooting.jpg', 'swimming.jpg', 'taekwondo.jpg', 'tennis.jpg',
    'track.jpg', 'triathlon.jpg', 'volleyball.jpg'
  ];