const events = [

    {
        "eventId": "0",
        "date": "2024",
        "headline": "Contact the VIAC Members to stay up to date",
        "description": "Join us at the VIAC Meetings.",
        // "image": "event_1",
        // "location": " ",
        "rsvp": "mailto:virginislandsac@gmail.com",
        // "directions": "https://maps.app.goo.gl/independence-run",
    },
];

export default events;