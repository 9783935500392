const achievements = [
        {
            achievementId: "1",
            year: "2023",
            discipline: "Basketball",
            description: "The Virgin Islands women's basketball team clinched a historic win against Puerto Rico at the San Salvador 2023 CAC Games, earning a medal for the first time in decades. Key players like Natalie Kleemann-Day and Imani Tate led the scoring, contributing significantly to the team's success. This victory highlighted the Virgin Islands' broader achievements at the Games, with athletes setting personal bests and records in swimming and beach volleyball reaching new highs. The team's triumph over the higher-ranked Puerto Ricans showcases their remarkable progress and determination in international competition.",
            image: "achievement_1",
            location: "San Salvador",
            competitionName: "Salvador 2023 Central American and Caribbean Games",
            category: "Women's Senior",
            link: "https://www.virginislandsolympics.org/underdog-triumph-virgin-islands-womens-basketball-teams-historic-victory-at-san-salvador-2023-cac-games/"
        },
        {
            achievementId: "2",
            year: "2022",
            discipline: "Boxing",
            description: "At the Tokyo 2022 World Athletics Championships, an electrifying performance in the 100m sprint shattered existing records and clinched a gold medal, bringing global attention to the athlete's extraordinary speed and technique. This achievement has been celebrated as a defining moment in the history of track and field for the Virgin Islands.",
            image: "achievement_2",
            location: "Tokyo, Japan",
            competitionName: "World Athletics Championships",
            category: "Boxing"
        },
        {
            achievementId: "3",
            year: "2021",
            discipline: "Swimming",
            description: "The FINA World Swimming Championships 2021 witnessed an unprecedented feat as the athlete set new world records in the butterfly events, sweeping the gold medals and establishing themselves as one of the greatest swimmers of their generation. This historic performance underscored the athlete's dominance in the sport.",
            image: "achievement_3",
            location: "Budapest, Hungary",
            competitionName: "FINA World Swimming Championships",
            category: "Butterfly Stroke"
        },
        {
            achievementId: "4",
            year: "2020",
            discipline: "Fencing",
            description: "A groundbreaking performance at the 2020 Olympic Games saw the athlete achieve a gold medal in the vault, a monumental victory that represented the pinnacle of fencing excellence. This win was particularly significant, symbolizing the athlete's perseverance and dedication to reaching the Olympic dream.",
            image: "achievement_4",
            location: "Tokyo, Japan",
            competitionName: "Olympic Games",
            category: "Vault"
        },
        {
            achievementId: "5",
            year: "2023",
            discipline: "Fencing",
            description: "Seventeen-year-old Kruz Schembri from the Virgin Islands secured a top-10 position in the Men’s Individual Foil at the 2023 Pan American Games in Santiago, Chile, just six days after his birthday. Ranking among 19 participants, Schembri was the youngest fencer in his category, showcasing his talent on an international stage.",
            image: "achievement_5",
            location: "Rome, Italy",
            competitionName: "Kruz Schembri Achieves Top-10 at Pan Am Games",
            category: "Fencing"
        },
        {
            achievementId: "6",
            year: "1988",
            discipline: "Yachting",
            description: "Peter Holmberg from the Virgin Islands made a significant mark in Olympic history by clinching a silver medal in the Finn class sailing event at the 1988 Seoul Olympics.",
            image: "achievement_6",
            location: "Seoul, Korea",
            competitionName: "1988 Seoul Olympic Games",
            category: "Yachting"
        },
        {
            achievementId: "7",
            year: "1987",
            discipline: "Athletics",
            description: "Flora Hyacinth, representing the Virgin Islands, made history in track and field by becoming the first female to surpass 45 feet in the triple jump in 1987. This milestone was achieved at a meet in Tuscaloosa, Alabama, where she jumped 13.73 meters. Although this record-setting jump was not officially recognized due to technicalities with measurement verification, Hyacinth's achievement is still celebrated in the sport's unofficial record books. Her groundbreaking performance remains a highlight of her illustrious career in athletics. ",
            image: "achievement_7",
            location: "Tuscaloosa, Alabama",
            competitionName: "first female to surpass 45 feet",
            category: "Track and Field",
            link: "https://en.wikipedia.org/wiki/Flora_Hyacinth"
        },
        {
            achievementId: "8",
            year: "2010",
            discipline: "Athletics",
            description: "Muhammad Halim '08, a Cornell standout and US Virgin Islands native, clinched a silver medal in the long jump at the Central American and Caribbean Championships in Mayaguez, Puerto Rico. His leap of 25-6 3/4 marked the second-best performance of his career. Halim also competed in the triple jump, securing a fifth-place finish. This achievement underscores his significant contribution to the track and field discipline.",
            image: "achievement_8",
            location: "MAYAGUEZ, Puerto Rico",
            competitionName: "Muhammad Halim Shines at CAC Championships",
            category: "long jump"
        }
];

export default achievements;
