import React, { useState } from 'react';

const Filter = ({ currentFilter, options, onFilterChange }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOptionClick = (option) => {
        onFilterChange(option);
        setIsOpen(false); // Close dropdown after selection
    };

    return (
        <div className='filters'>
            <div className='filter-select' onClick={() => setIsOpen(!isOpen)}>
                Filter by discipline &nbsp;&#40;{currentFilter}&#41;
            </div>
            {isOpen && (
                <div className='filter-options'>
                    {options.map(option => (
                        <div 
                            key={option} 
                            className={`filter-option ${currentFilter === option ? 'active' : ''}`} 
                            onClick={() => handleOptionClick(option)}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Filter;