import React, { useState } from 'react';

const Footer = ({ isFixed }) => {
  const [showOverlay, setShowOverlay] = useState(false);

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  };

  return (
    <>
      <footer className={`footer ${isFixed ? "fixed-footer" : ""}`} onClick={toggleOverlay}>
        <div className="footer-content">
          <p>&copy; VIAC 2024</p>
          <p>Terms + Conditions</p>
        </div>
      </footer>
      {showOverlay && (
        <div className="overlay" onClick={toggleOverlay}>
          <div className="overlay-content" onClick={(e) => e.stopPropagation()}> {/* This prevents the overlay from closing when its content is clicked */}
            <button className="close-button" onClick={toggleOverlay}>CLOSE</button>
            <p>
              <h4>PRIVACY POLICY</h4>
              Last updated March 26, 2024
              This privacy notice for Virgin Islands ATHLETES' Commission ("we," "us," or "our"),
              describes how and why we might collect, store, use, and/or share ("process") your
              information when you use our services ("Services"), such as when you:
              Visit our website at https://www.virginislandsac.com/, or any website of ours
              that links to this privacy notice
              Engage with us in other related ways, including any sales, marketing, or
              events
              Questions or concerns? Reading this privacy notice will help you understand your
              privacy rights and choices. If you do not agree with our policies and practices, please
              do not use our Services. If you still have any questions or concerns, please contact
              us at virginislandsac@gmail.com.
              SUMMARY OF KEY POINTS
              This summary provides key points from our privacy notice, but you can find
              out more details about any of these topics by clicking the link following each
              key point or by using our table of contents below to find the section you are
              looking for.
              What personal information do we process? When you visit, use, or navigate our
              Services, we may process personal information depending on how you interact with
              us and the Services, the choices you make, and the products and features you use.
              Learn more about personal information you disclose to us.
              Do we process any sensitive personal information? We do not process sensitive
              personal information.
              Do we receive any information from third parties? We do not receive any
              information from third parties.
              How do we process your information? We process your information to provide,
              improve, and administer our Services, communicate with you, for security and fraud
              prevention, and to comply with law. We may also process your information for other
              purposes with your consent. We process your information only when we have a valid
              legal reason to do so. Learn more about how we process your information.
              In what situations and with which parties do we share personal information?
              We may share information in specific situations and with specific third parties. Learn
              more about when and with whom we share your personal information.
              What are your rights? Depending on where you are located geographically, the
              applicable privacy law may mean you have certain rights regarding your personal
              information. Learn more about your privacy rights.
              How do you exercise your rights? The easiest way to exercise your rights is by
              submitting a data subject access request, or by contacting us. We will consider and
              act upon any request in accordance with applicable data protection laws.
              Want to learn more about what we do with any information we collect?
              Review theprivacy notice in full
              .
              TABLE OF CONTENTS
              1. WHAT INFORMATION DO WE COLLECT?
              2. HOW DO WE PROCESS YOUR INFORMATION?
              3.
              WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONALINFORMATION?
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              5. HOW LONG DO WE KEEP YOUR INFORMATION?
              6. DO WE COLLECT INFORMATION FROM MINORS?
              7. WHAT ARE YOUR PRIVACY RIGHTS?
              8. CONTROLS FOR DO-NOT-TRACK FEATURES
              9. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              10. DO WE MAKE UPDATES TO THIS NOTICE?
              11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECTFROM YOU?
              1. WHAT INFORMATION DO WE COLLECT?
              Personal information you disclose to us
              In Short:
              We collect personal information that you provide to us.
              We collect personal information that you voluntarily provide to us when you
              expressan interest in obtaining information about us or our products and Services, when youparticipate in activities on the Services, or otherwise when you contact us.
              Sensitive Information.
              We do not process sensitive information.
              All personal information that you provide to us must be true, complete, and accurate,and you must notify us of any changes to such personal information.
              2. HOW DO WE PROCESS YOUR INFORMATION?
              In Short:
              We process your information to provide, improve, and administer ourServices, communicate with you, for security and fraud prevention, and to complywith law. We may also process your information for other purposes with your consent.
              We process your personal information for a variety of reasons, depending onhow you interact with our Services, including:
              To save or protect an individual's vital interest.
              We may process yourinformation when necessary to save or protect an individual’s vital interest,such as to prevent harm.
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESSYOUR INFORMATION?
              In Short:
              We only process your personal information when we believe it is necessaryand we have a valid legal reason (i.e.
              ,
              legal basis) to do so under applicable law, likewith your consent, to comply with laws, to provide you with services to enter into or
              fulfill
              our contractual obligations, to protect your rights, or to
              fulfill
              our legitimatebusiness interests.
              If you are located in the EU or UK, this section applies to you.
              The General Data Protection Regulation (GDPR) and UK GDPR require us to explainthe valid legal bases we rely on in order to process your personal information. Assuch, we may rely on the following legal bases to process your personal information:
              Consent.
              We may process your information if you have given us permission(i.e.
              ,
              consent) to use your personal information for a specific purpose. You canwithdraw your consent at any time. Learn more about
              withdrawing yourconsent
              .
              26.03.24, 21:40
              https://app..io/dashboard/website/c2687b2c-5a98-479a-ac6b-19c899f9559b/privacy-policy 3/10
              Legal Obligations.
              We may process your information where we believe it isnecessary for compliance with our legal obligations, such as to cooperate witha law enforcement body or regulatory agency, exercise or defend our legalrights, or disclose your information as evidence in litigation in which we areinvolved.
              Vital Interests.
              We may process your information where we believe it isnecessary to protect your vital interests or the vital interests of a third party,such as situations involving potential threats to the safety of any person.
              If you are located in Canada, this section applies to you.
              We may process your information if you have given us specific permission (i.e.
              ,
              express consent) to use your personal information for a specific purpose, or insituations where your permission can be inferred (i.e.
              ,
              implied consent). Youcan
              withdraw your consent
              at any time.
              In some exceptional cases, we may be legally permitted under applicable law toprocess your information without your consent, including, for example:
              If collection is clearly in the interests of an individual and consent cannot beobtained in a timely way
              For investigations and fraud detection and prevention
              For business transactions provided certain conditions are met
              If it is contained in a witness statement and the collection is necessary toassess, process, or settle an insurance claim
              For identifying injured, ill, or deceased persons and communicating with nextof kin
              If we have reasonable grounds to believe an individual has been, is, or may bevictim of financial abuse
              If it is reasonable to expect collection and use with consent would compromisethe availability or the accuracy of the information and the collection isreasonable for purposes related to investigating a breach of an agreement or acontravention of the laws of Canada or a province
              If disclosure is required to comply with a subpoena, warrant, court order, orrules of the court relating to the production of records
              If it was produced by an individual in the course of their employment, business,or profession and the collection is consistent with the purposes for which theinformation was produced
              If the collection is solely for journalistic, artistic, or literary purposes
              If the information is publicly available and is specified by the regulations
              4. WHEN AND WITH WHOM DO WE SHARE YOURPERSONAL INFORMATION?
              In Short:
              We may share information in specific situations described in this sectionand/or with the following
              third parties.
              We
              may need to share your personal information in the following situations:
              Business Transfers.
              We may share or transfer your information in connectionwith, or during negotiations of, any merger, sale of company assets, financing,or acquisition of all or a portion of our business to another company.
              5. HOW LONG DO WE KEEP YOUR INFORMATION?
              In Short:
              We keep your information for as long as necessary to
              fulfill
              the purposesoutlined in this privacy notice unless otherwise required by law.
              We will only keep your personal information for as long as it is necessary for thepurposes set out in this privacy notice, unless a longer retention period is required orpermitted by law (such as tax, accounting, or other legal requirements).
              When we have no ongoing legitimate business need to process your personalinformation, we will either delete or
              anonymize
              such information, or, if this is not
              26.03.24, 21:40
              https://app.termly.io/dashboard/website/c2687b2c-5a98-479a-ac6b-19c899f9559b/privacy-policy 4/10
              possible (for example, because your personal information has been stored in backuparchives), then we will securely store your personal information and isolate it fromany further processing until deletion is possible.
              6. DO WE COLLECT INFORMATION FROM MINORS?
              In Short:
              We do not knowingly collect data from or market to
              children under 18 yearsof age
              .
              We do not knowingly solicit data from or market to children under 18 years of age. Byusing the Services, you represent that you are at least 18 or that you are the parentor guardian of such a minor and consent to such minor dependent’s use of theServices. If we learn that personal information from users less than 18 years of agehas been collected, we will deactivate the account and take reasonable measures topromptly delete such data from our records. If you become aware of any data wemay have collected from children under age 18, please contact us at
              __________
              .
              7. WHAT ARE YOUR PRIVACY RIGHTS?
              In Short:
              In some regions, such as
              the European Economic Area (EEA), UnitedKingdom (UK), Switzerland, and Canada
              , you have rights that allow you greateraccess to and control over your personal information.
              You may review, change, orterminate your account at any time.
              In some regions (like
              the EEA, UK, Switzerland, and Canada
              ), you have certainrights under applicable data protection laws. These may include the right (i) torequest access and obtain a copy of your personal information, (ii) to requestrectification or erasure; (iii) to restrict the processing of your personal information; (iv)if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to theprocessing of your personal information. You can make such a request by contactingus by using the contact details provided in the section
              "
              HOW CAN YOU CONTACTUS ABOUT THIS NOTICE?
              "
              below.
              We will consider and act upon any request in accordance with applicable dataprotection laws.
              If you are located in the EEA or UK and you believe we are unlawfully processingyour personal information, you also have the right to complain to your
              Member Statedata protection authority
              or
              UK data protection authority
              .
              If you are located in Switzerland, you may contact the
              Federal Data Protection andInformation Commissioner
              .
              Withdrawing your consent:
              If we are relying on your consent to process yourpersonal information,
              which may be express and/or implied consent depending onthe applicable law,
              you have the right to withdraw your consent at any time. You canwithdraw your consent at any time by contacting us by using the contact detailsprovided in the section
              "
              HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              "
              below
              .
              However, please note that this will not affect the lawfulness of the processing beforeits withdrawal nor,
              when applicable law allows,
              will it affect the processing of yourpersonal information conducted in reliance on lawful processing grounds other thanconsent.
              If you have questions or comments about your privacy rights, you may email us at
              virginislandsac@gmail.com
              .
              8. CONTROLS FOR DO-NOT-TRACK FEATURES
              Most web browsers and some mobile operating systems and mobile applicationsinclude a Do-Not-Track (
              "DNT"
              ) feature or setting you can activate to signal yourprivacy preference not to have data about your online browsing activities monitoredand collected. At this stage no uniform technology standard for
              recognizing
              andimplementing DNT signals has been
              finalized
              . As such, we do not currently respondto DNT browser signals or any other mechanism that automatically communicatesyour choice not to be tracked online. If a standard for online tracking is adopted thatwe must follow in the future, we will inform you about that practice in a revisedversion of this privacy notice.
              26.03.24, 21:40
              https://app..io/dashboard/website/c2687b2c-5a98-479a-ac6b-19c899f9559b/privacy-policy 5/10
              9. DO UNITED STATES RESIDENTS HAVE SPECIFICPRIVACY RIGHTS?
              In Short:
              If you are a resident of
              California
              , Colorado
              , Connecticut
              , Utah
              or Virginia
              ,you are granted specific rights regarding access to your personal information.
              What categories of personal information do we collect?
              We have collected the following categories of personal information in the past twelve(12) months:
              Category
              Examples
              Collected
              A. Identifiers
              Contact details, such as real name, alias,postal address, telephone or mobilecontact number, unique personal identifier,online identifier, Internet Protocol address,email address, and account name
              NO
              B. Personal information asdefined in the CaliforniaCustomer Records statute
              Name, contact information, education,employment, employment history, andfinancial information
              NO
              C
              . Protected classificationcharacteristics under stateor federal law
              Gender and date of birth
              NO
              D
              . Commercial information
              Transaction information, purchase history,financial details, and payment information
              NO
              E
              . Biometric information
              Fingerprints and voiceprints
              NO
              F
              . Internet or other similarnetwork activity
              Browsing history, search history, online
              behavior
              , interest data, and interactionswith our and other websites, applications,systems, and advertisements
              NO
              G
              . Geolocation data
              Device location
              NO
              H
              . Audio, electronic, visual,thermal, olfactory, or similarinformation
              Images and audio, video or call recordingscreated in connection with our businessactivities
              NO
              I
              . Professional oremployment-relatedinformation
              Business contact details in order toprovide you our Services at a businesslevel or job title, work history, andprofessional qualifications if you apply fora job with us
              NO
              J
              . Education Information
              Student records and directory information
              NO
              K
              . Inferences drawn fromcollected personalinformation
              Inferences drawn from any of the collectedpersonal information listed above to createa profile or summary about, for example,an individual’s preferences andcharacteristics
              NO
              L
              . Sensitive personalInformation
              NO
              We may also collect other personal information outside of these categories throughinstances where you interact with us in person, online, or by phone or mail in thecontext of:
              Receiving help through our customer support channels;
              Participation in customer surveys or contests; and
              Facilitation in the delivery of our Services and to respond to your inquiries.
              How do we use and share your personal information?
              26.03.24, 21:40
              https://app..io/dashboard/website/c2687b2c-5a98-479a-ac6b-19c899f9559b/privacy-policy 6/10
              Learn about how we use your personal information in the section,
              "
              HOW DO WEPROCESS YOUR INFORMATION?
              "
              Will your information be shared with anyone else?
              We may disclose your personal information with our service providers pursuant to awritten contract between us and each service provider. Learn more about how wedisclose personal information to in the section,
              "
              WHEN AND WITH WHOM DO WESHARE YOUR PERSONAL INFORMATION?
              "
              We may use your personal information for our own business purposes, such as forundertaking internal research for technological development and demonstration. Thisis not considered to be
              "selling"
              of your personal information.
              We have not disclosed, sold, or shared any personal information to third parties for abusiness or commercial purpose in the preceding twelve (12) months. We
              will not sellor share personal information in the future belonging to website visitors, users, andother consumers.
              California Residents
              California Civil Code Section 1798.83, also known as the
              "Shine The Light"
              lawpermits our users who are California residents to request and obtain from us, once ayear and free of charge, information about categories of personal information (if any)we disclosed to third parties for direct marketing purposes and the names andaddresses of all third parties with which we shared personal information in theimmediately preceding calendar year. If you are a California resident and would liketo make such a request, please submit your request in writing to us using the contactinformation provided below.
              If you are under 18 years of age, reside in California, and have a registered accountwith the Services, you have the right to request removal of unwanted data that youpublicly post on the Services. To request removal of such data, please contact ususing the contact information provided below and include the email addressassociated with your account and a statement that you reside in California. We willmake sure the data is not publicly displayed on the Services, but please be awarethat the data may not be completely or comprehensively removed from all oursystems (e.g.
              ,
              backups, etc.).
              CCPA Privacy Notice
              This section applies only to California residents. Under the California ConsumerPrivacy Act (CCPA), you have the rights listed below.
              The California Code of Regulations defines a
              "residents"
              as:
              (1) every individual who is in the State of California for other than a temporary ortransitory purpose and
              (2) every individual who is domiciled in the State of California who is outside theState of California for a temporary or transitory purpose
              All other individuals are defined as
              "non-residents."
              If this definition of
              "resident"
              applies to you, we must adhere to certain rights andobligations regarding your personal information.
              Your rights with respect to your personal data
              Right to request deletion of the data — Request to delete
              You can ask for the deletion of your personal information. If you ask us to delete yourpersonal information, we will respect your request and delete your personalinformation, subject to certain exceptions provided by law, such as (but not limited to)the exercise by another consumer of his or her right to free speech, our compliancerequirements resulting from a legal obligation, or any processing that may berequired to protect against illegal activities.
              Right to be informed — Request to know
              Depending on the circumstances, you have a right to know:
              whether we collect and use your personal information;
              26.03.24, 21:40
              https://app..io/dashboard/website/c2687b2c-5a98-479a-ac6b-19c899f9559b/privacy-policy 7/10
              the categories of personal information that we collect;
              the purposes for which the collected personal information is used;
              whether we sell or share personal information to third parties;
              the categories of personal information that we sold, shared, or disclosed for abusiness purpose;
              the categories of third parties to whom the personal information was sold,shared, or disclosed for a business purpose;
              the business or commercial purpose for collecting, selling, or sharing personalinformation; and
              the specific pieces of personal information we collected about you.
              In accordance with applicable law, we are not obligated to provide or deleteconsumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.
              Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights
              We will not discriminate against you if you exercise your privacy rights.
              Right to Limit Use and Disclosure of Sensitive Personal Information
              We do not process consumer's sensitive personal information.
              Verification process
              Upon receiving your request, we will need to verify your identity to determine you arethe same person about whom we have the information in our system. Theseverification efforts require us to ask you to provide information so that we can match itwith information you have previously provided us. For instance, depending on thetype of request you submit, we may ask you to provide certain information so that wecan match the information you provide with the information we already have on file,or we may contact you through a communication method (e.g.
              ,
              phone or email) thatyou have previously provided to us. We may also use other verification methods asthe circumstances dictate.
              We will only use personal information provided in your request to verify your identityor authority to make the request. To the extent possible, we will avoid requestingadditional information from you for the purposes of verification. However, if we cannotverify your identity from the information already maintained by us, we may requestthat you provide additional information for the purposes of verifying your identity andfor security or fraud-prevention purposes. We will delete such additionally providedinformation as soon as we finish verifying you.
              Other privacy rights
              You may object to the processing of your personal information.
              You may request correction of your personal data if it is incorrect or no longerrelevant, or ask to restrict the processing of the information.
              You can designate an
              authorized
              agent to make a request under the CCPA onyour behalf. We may deny a request from an
              authorized
              agent that does notsubmit proof that they have been validly
              authorized
              to act on your behalf inaccordance with the CCPA.
              You may request to opt out from future selling or sharing of your personalinformation to third parties. Upon receiving an opt-out request, we will act uponthe request as soon as feasibly possible, but no later than fifteen (15) daysfrom the date of the request submission.
              To exercise these rights, you can contact us
              by submitting a
              data subject accessrequest
              ,
              or by referring to the contact details at the bottom of this document. If youhave a complaint about how we handle your data, we would like to hear from you.
              Colorado Residents
              This section applies only to Colorado residents. Under the Colorado Privacy Act(CPA), you have the rights listed below. However, these rights are not absolute, and
              26.03.24, 21:40
              https://app..io/dashboard/website/c2687b2c-5a98-479a-ac6b-19c899f9559b/privacy-policy 8/10
              in certain cases, we may decline your request as permitted by law.
              Right to be informed whether or not we are processing your personal data
              Right to access your personal data
              Right to correct inaccuracies in your personal data
              Right to request deletion of your personal data
              Right to obtain a copy of the personal data you previously shared with us
              Right to opt out of the processing of your personal data if it is used for targetedadvertising, the sale of personal data, or profiling in furtherance of decisionsthat produce legal or similarly significant effects (
              "profiling"
              )
              To submit a request to exercise
              these
              rights described above, please
              email
              virginislandsac@gmail.com
              or
              submit a
              data subject access request
              .
              If we decline to take action regarding your request and you wish to appeal ourdecision, please email us at
              __________
              . Within forty-five (45) days of receipt of anappeal, we will inform you in writing of any action taken or not taken in response tothe appeal, including a written explanation of the reasons for the decisions.
              Connecticut Residents
              This section applies only to Connecticut residents. Under the Connecticut DataPrivacy Act (CTDPA), you have the rights listed below. However, these rights are notabsolute, and in certain cases, we may decline your request as permitted by law.
              Right to be informed whether or not we are processing your personal data
              Right to access your personal data
              Right to correct inaccuracies in your personal data
              Right to request deletion of your personal data
              Right to obtain a copy of the personal data you previously shared with us
              Right to opt out of the processing of your personal data if it is used for targetedadvertising, the sale of personal data, or profiling in furtherance of decisionsthat produce legal or similarly significant effects (
              "profiling"
              )
              To submit a request to exercise
              these
              rights described above, please
              email
              virginislandsac@gmail.com
              or
              submit a
              data subject access request
              .
              If we decline to take action regarding your request and you wish to appeal ourdecision, please email us at
              __________
              . Within sixty (60) days of receipt of anappeal, we will inform you in writing of any action taken or not taken in response tothe appeal, including a written explanation of the reasons for the decisions.
              Utah Residents
              This section applies only to Utah residents. Under the Utah Consumer Privacy Act(UCPA), you have the rights listed below. However, these rights are not absolute, andin certain cases, we may decline your request as permitted by law.
              Right to be informed whether or not we are processing your personal data
              Right to access your personal data
              Right to request deletion of your personal data
              Right to obtain a copy of the personal data you previously shared with us
              Right to opt out of the processing of your personal data if it is used for targetedadvertising or the sale of personal data
              To submit a request to exercise
              these
              rights described above, please
              email
              virginislandsac@gmail.com
              or
              submit a
              data subject access request
              .
              Virginia Residents
              Under the Virginia Consumer Data Protection Act (VCDPA):
              "Consumer"
              means a natural person who is a resident of the Commonwealth actingonly in an individual or household context. It does not include a natural person actingin a commercial or employment context.
              26.03.24, 21:40
              https://app..io/dashboard/website/c2687b2c-5a98-479a-ac6b-19c899f9559b/privacy-policy 9/10
              "Personal data"
              means any information that is linked or reasonably linkable to anidentified or identifiable natural person.
              "Personal data"
              does not include de-identifieddata or publicly available information.
              "Sale of personal data"
              means the exchange of personal data for monetaryconsideration.
              If this definition of
              "consumer"
              applies to you, we must adhere to certain rights andobligations regarding your personal data.
              Your rights with respect to your personal data
              Right to be informed whether or not we are processing your personal data
              Right to access your personal data
              Right to correct inaccuracies in your personal data
              Right to request deletion of your personal data
              Right to obtain a copy of the personal data you previously shared with us
              Right to opt out of the processing of your personal data if it is used for targetedadvertising, the sale of personal data, or profiling in furtherance of decisionsthat produce legal or similarly significant effects (
              "profiling"
              )
              Exercise your rights provided under the Virginia VCDPA
              You may
              contact us by email at
              virginislandsac@gmail.com
              or
              submit a
              data subjectaccess request
              .
              If you are using an
              authorized
              agent to exercise your rights, we may deny a request ifthe
              authorized
              agent does not submit proof that they have been validly
              authorized
              toact on your behalf.
              Verification process
              We may request that you provide additional information reasonably necessary toverify you and your consumer's request. If you submit the request through an
              authorized
              agent, we may need to collect additional information to verify your identitybefore processing your request.
              Upon receiving your request, we will respond without undue delay, but in all cases,within forty-five (45) days of receipt. The response period may be extended once byforty-five (45) additional days when reasonably necessary. We will inform you of anysuch extension within the initial 45-day response period, together with the reason forthe extension.
              Right to appeal
              If we decline to take action regarding your request, we will inform you of our decisionand reasoning behind it. If you wish to appeal our decision, please email us at
              __________
              . Within sixty (60) days of receipt of an appeal, we will inform you inwriting of any action taken or not taken in response to the appeal, including a writtenexplanation of the reasons for the decisions. If your appeal is denied, you maycontact the
              Attorney General to submit a complaint
              .
              10. DO WE MAKE UPDATES TO THIS NOTICE?
              In Short:
              Yes, we will update this notice as necessary to stay compliant with relevantlaws.
              We may update this privacy notice from time to time. The updated version will beindicated by an updated
              "Revised"
              date and the updated version will be effective assoon as it is accessible. If we make material changes to this privacy notice, we maynotify you either by prominently posting a notice of such changes or by directlysending you a notification. We encourage you to review this privacy notice frequentlyto be informed of how we are protecting your information.
              11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              26.03.24, 10/10
              If you have questions or comments about this notice, you may
              contact us by post at:
              Virgin Islands ATHLETES' Commission
              __________
              __________
              ,
              Virgin Islands
            </p>
            {/* Your privacy policy content goes here */}
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;