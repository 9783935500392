const news = [
    {
        newsId: "0",
        date: "June 2024",
        headline: "Recap of the lates event",
        description: "The Virgin Islands Athlete's Commission recently organized a successful event, drawing community support and attention. Highlighting the spirit of giving, participants engaged in various activities, raising significant funds for local causes. The event, marked by enthusiasm and generosity, showcased the community's commitment to making a difference. It not only served as a platform for athletes to give back but also united the Virgin Islands community in a common goal. The commission extends heartfelt thanks to all who contributed, promising more impactful initiatives in the future.",
        image: "news_0",
        location: "St. Thomas",
        category: "News",
        // link: "https://www.collinsdictionary.com/dictionary/english/charity-event"
    },
    {
        newsId: "1",
        date: "2024",
        headline: "Get your OLY title",
        description: "Celebrate your journey of hard work and adherence to Olympic ideals by securing your OLY title. Acknowledge your Olympic success by joining the community of Olympians who have embraced this recognition. Click to register today and solidify your place among esteemed athletes.",
        image: "news_1",
        location: "Virgin Islands",
        category: "News",
        link: "https://olympics.com/athlete365/beoly/"
    },

];

export default news;

