import React from 'react';
import about from '../data/about';
import Footer from '../components/Footer';
import placeholder from '../assets/viac-placeholder.jpg';
import useAdjustBodyHeight from '../hooks/useAdjustBodyHeight';

const AboutPage = () => {

  useAdjustBodyHeight();

  return (
    <div className='content'>
      <div className='headline'>
      <h4>
      WE ARE <br/>THE ATHLETES' COMMISSION OF THE VIRGIN&nbsp;ISLANDS
      </h4>
      The Virgin Islands Athletes' Commission is an organization dedicated to representing and advocating for the interests of athletes from the Virgin Islands.
      </div>


      <div className='container-columns'>

        <div className='aboutDesktop' >
          {about.map((item, index) => (
            <div key={index} className='aboutItem'>
              <div className='imgWrapper'>
                {item.image ? (
                  <img className='aboutImg' src={require(`../assets/images/about/${item.image}.jpg`)} alt={item.name} />
                ) : (
                  <img className='aboutImg' src={placeholder} alt="Virgin Islands ATHLETES' Commission Blazon" />
                )}
              </div>
              <div className='descriptionContainer'>
                <div className='topContainer'>
                  <p className='note'>{item.role}</p>
                  <h3>{item.name}</h3>
                </div>
                <div className='bottomContainer'>
                  <p className='text'>{item.description}</p>
                  {item.mail && (
                    <a href="mailto:virginislandsac@gmail.com" className="button">contact</a>
                  )}
                </div>
              </div>
            </div>
          ))}
          <Footer isFixed={false} />

        </div>

      </div>

    </div>

  );
};

export default AboutPage;