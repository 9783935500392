import { useEffect } from 'react';

const useAdjustBodyHeight = () => {
  useEffect(() => {
    const adjustBodyHeight = () => {
      document.querySelector('.content').style.maxHeight = `${window.innerHeight}px`;
    };

    window.addEventListener('resize', adjustBodyHeight);
    adjustBodyHeight();

    // Clean up the event listener when the component unmounts or when the effect re-runs
    return () => window.removeEventListener('resize', adjustBodyHeight);
  }, []);
}

export default useAdjustBodyHeight;