import React, { useState, useEffect, useRef } from 'react';
import aHeadline from '../assets/viac-athletes.svg';
import Placeholder from '../assets/viac-placeholder.jpg';
import athletesData from '../data/athletesData';
import Footer from '../components/Footer';
import Filter from '../components/Filter';
// import arrowRight from '../assets/arrow.svg';
// import arrowLeft from '../assets/arrow-left.svg';

const AthletesPage = () => {
  const [filter, setFilter] = useState('All');
  const athleteRowRefs = useRef([]);
  const [arrowsVisible, setArrowsVisible] = useState([]);

  const disciplines = ['All', ...new Set(athletesData.map(item => item.discipline))];

  useEffect(() => {
    const adjustBodyHeight = () => {
      document.querySelector('.content').style.maxHeight = `${window.innerHeight}px`;
    };

    window.addEventListener('resize', adjustBodyHeight);
    adjustBodyHeight();

    const checkSliderWidth = () => {
      const visibility = athleteRowRefs.current.map(ref => ({
        left: ref.scrollLeft > 0,
        right: ref.scrollWidth > ref.clientWidth && ref.scrollWidth - ref.scrollLeft > ref.clientWidth,
      }));
      setArrowsVisible(visibility);
    };

    athleteRowRefs.current = athleteRowRefs.current.slice(0, athletesData.filter(({ discipline }) => filter === 'All' || discipline === filter).length);

    athleteRowRefs.current.forEach((ref) => {
      if (ref) {
        ref.addEventListener('scroll', checkSliderWidth);
      }
    });

    window.addEventListener('resize', checkSliderWidth);
    checkSliderWidth();

    return () => {
      window.removeEventListener('resize', adjustBodyHeight);
      athleteRowRefs.current.forEach((ref) => {
        if (ref) {
          ref.removeEventListener('scroll', checkSliderWidth);
        }
      });
    };
  }, [filter]);

  const addRef = (el, index) => {
    if (el) {
      athleteRowRefs.current[index] = el;
    }
  };

  const handleScroll = (index, direction) => {
    const element = athleteRowRefs.current[index];
    // Calculate 80% of the viewport width
    const viewportWidth = window.innerWidth;
    const eightyVWInPixels = viewportWidth * 0.8;
    // Determine scroll direction
    const scrollAmount = direction === 'left' ? -eightyVWInPixels : eightyVWInPixels;
    element.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  return (
    <div className='content'>
      <div className='headline'>
        <img src={aHeadline} alt="Virgin Islands Athletes Commission Wordmark" />
      </div>
      <Filter currentFilter={filter} options={disciplines} onFilterChange={setFilter} />
      <div className='athletes-wrapper'>
        {athletesData.filter(({ discipline }) => filter === 'All' || discipline === filter)
          .map(({ discipline, athletes }, index) => (
            <div key={discipline} className='discipline-row'>
              {arrowsVisible[index]?.left && (
                <div className="arrowleft" onClick={() => handleScroll(index, 'left')}>
                  <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.7749 1L2.0001 10.7747L11.7749 20.5495" stroke-width="1" />
                  </svg>
                </div>
              )}
              {arrowsVisible[index]?.right && (
                <div className="arrowright" onClick={() => handleScroll(index, 'right')}>
                  <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L10.7748 10.7747L1 20.5495" stroke-width="1" />
                  </svg>

                </div>
              )}
              <div className='discipline-headline'>{discipline}</div>
              <div className='athletes-row' ref={(el) => addRef(el, index)}>
                {athletes.map((athlete, athleteIndex) => (
                  <div key={athleteIndex} className='athlete-card'>
                    <img src={athlete.photo || Placeholder} alt={athlete.name} draggable="false" />
                    <div className='athlete-overlay'>
                      <div className='athlete-name'>{athlete.name}</div>
                      {athlete.specification && <div className='athlete-specification'>{athlete.specification}</div>}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        <Footer isFixed={false} />
      </div>
    </div>
  );
};

export default AthletesPage;
