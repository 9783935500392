import React, { useState } from 'react';
import achievements from '../data/achievements';
import Footer from '../components/Footer';
import Filter from '../components/Filter';
import useAdjustBodyHeight from '../hooks/useAdjustBodyHeight'; 

const AchievementsPage = () => {
  const [filter, setFilter] = useState('All');
  const [showRecent, setShowRecent] = useState(true);
  const disciplines = ['All', ...new Set(achievements.map(item => item.discipline))];

  useAdjustBodyHeight();

  const currentYear = new Date().getFullYear();
  const filteredAchievements = achievements
    .filter(achievement => filter === 'All' || achievement.discipline === filter)
    .filter(achievement => {
      const yearsAgo = currentYear - parseInt(achievement.year);
      return showRecent ? yearsAgo <= 5 : yearsAgo > 5;
    });
  const isSingleAchievement = filteredAchievements.length === 1;
  const sortedAchievements = [...filteredAchievements].sort((a, b) => parseInt(b.year) - parseInt(a.year));

  return (
    <div className='content'>
      <div className='filterContainer'>
        <div className='toggleButton'>
          <div
            onClick={() => setShowRecent(true)}
            className={showRecent ? 'activeButtonStyle' : 'inactiveButtonStyle'}
          >
            Recent
          </div>
          <div
            onClick={() => setShowRecent(false)}
            className={!showRecent ? 'activeButtonStyle' : 'inactiveButtonStyle'}
          >
            Historical
          </div>
        </div>


        <Filter
          currentFilter={filter}
          options={disciplines}
          onFilterChange={setFilter}
        />
      </div>


      <div className='containerAchievements' style={{ height: isSingleAchievement ? '100vh' : 'auto' }}>
        {
          filteredAchievements.length === 0 ? (
            <div className='noAchievements'>
              No achievements found.&nbsp;<span onClick={() => setFilter('All')}>Go back to all filters</span>
            </div>
          ) : (
            sortedAchievements.map((achievement, index) => (
              <div
                className={`achievementContainer ${index % 2 === 0 ? 'row' : 'row-reverse'}`}
                key={achievement.achievementId}
                style={{
                  height: filteredAchievements.length === 1 ? 'calc(100% - 33px)' : 'auto',
                }}
              >
                <svg className='circle' xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <circle cx="11.0002" cy="10.6653" r="10.6272" fill="white" />
                </svg>
                <svg className='half-circle' xmlns="http://www.w3.org/2000/svg" width="11" height="22" viewBox="0 0 11 22" fill="none">
                  <path d="M10.6272 11C10.6272 16.8692 5.86924 21.6272 0 21.6272C0 21.6272 6.07967e-05 16.8692 6.07967e-05 11C6.07967e-05 5.13076 0 0.372803 0 0.372803C5.86924 0.372803 10.6272 5.13076 10.6272 11Z" fill="white" />
                </svg>
                <div className='imageContainer' style={{ justifyContent: index % 2 === 0 ? 'flex-start' : 'flex-end' }}>
                  <img className='achievementImg' src={require(`../assets/images/AchievementsPage/${achievement.image}.jpg`)} alt={achievement.description} />
                </div>
                <div className='detailContainer' style={{ textAlign: index % 2 === 0 ? 'left' : 'right', alignItems: index % 2 === 0 ? 'flex-start' : 'flex-end', }}>
                  <h2> {achievement.year} </h2>
                  <div className='descriptionContainer'>
                    <p>
                      {achievement.competitionName}&nbsp;&mdash;&nbsp;{achievement.discipline}<br /> <br />
                      <p className='text'>{achievement.description}</p>
                    </p>
                    {achievement.link && (
                      <a href={achievement.link} target="_blank" rel="noopener noreferrer" className="button">Read More</a>
                    )}
                  </div>
                </div>
              </div>))
          )
        }

        <Footer isFixed={false} />
      </div>
    </div>
  );
};

export default AchievementsPage;