import React from 'react';
import Blazon from '../assets/blazon-small.svg';
import navigationLinks from '../data/navigationLinks';
import { NavLink } from 'react-router-dom';

// Correct way to receive `toggleMenu` prop
const Overlay = ({ toggleMenu }) => {

    return (
        <div>
            <div className='header-overlay'>
                <div className="header-left">
                    <div className='blazon'>
                        <img src={Blazon} alt="Virgin Islands Athletes Commission Blazon" />
                    </div>
                    <div>
                        VIRGIN&nbsp;ISLANDS<br />
                        ATHLETES'&nbsp;Commission
                    </div>
                </div>

                <div className='nav-mobile' onClick={toggleMenu}>
                    [CLOSE]
                </div>
            </div>

            <nav>
                <ul>
                    {navigationLinks.map(({ path, name }) => (
                        <NavLink to={path} className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>
                        <li key={path} onClick={() => toggleMenu()}>
                            
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 10" aria-hidden="true" focusable="false">
                                    <path className="st0" d="M6.5,9.5L5.8,8.7l3.2-3.2H0.8V4.5h8.2L5.8,1.3l0.8-0.8L11,5L6.5,9.5z" />
                                </svg>
                                {name}
                          
                        </li>
                        </NavLink>
                    ))}
                </ul>
            </nav>

        </div>

    );
}

export default Overlay;
