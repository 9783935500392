const about = [
    {
        aboutId: "1",
        role: "President",
        name: "Natalie Kleeman-Day",
        description: "Natalie Kleemann-Day is a basketball player from the Virgin Islands and the President of the Virgin Islands Athletes' Commission. Winning tournaments like the CAC Games highlights the talent from the Virgin Islands, says Kleemann-Day, who played a key role in the team’s victory. Having played professionally in Europe, Australia, China, and Puerto Rico, and D1 Basketball at Howard University and the University of Portland, she emphasizes the growth and success of USVI Women’s Basketball. Tracing her heritage to the Virgin Islands, she sees the team as role models for aspiring athletes. ",
        image: "natalie",
        mail: "natalie@virginislandsac.com"
    },
    {
        aboutId: "2",
        role: "Vice President",
        name: "Mikaela Smith",
        description: "Mikaela Smith is an 800m runner, the 800m indoor national record holder, and the Vice-President of the Virgin Islands Athletes' Commission.",
        image: "mikaela",
        mail: "mikaela@virginislandsac.com"
    },
    {
        aboutId: "3",
        role: "Member",
        name: "Natalie Kuipers",
        description: "Natalia Jean Kuipers is a swimmer representing the Virgin Islands. She competed in the women's 400-meter freestyle at the 2020 Summer Olympics.",
        image: "natalie-k",
        mail: "natalie-kuipers@virginislandsac.com"
    },
    {
        aboutId: "5",
        role: "Member",
        name: "Yashira Rhymer-Stuart",
        description: "Yashira Rhymer is the High Jump National Record Holder representing the Virgin Islands.",
        image: "yashira",
        mail: "yashira@virginislandsac.com"
    },
    {
        aboutId: "6",
        role: "Member",
        name: "Susana Fornaris",
        description: "Susana Fornaris is a fencer representing the Virgin Islands.",
        image: "susana",
        mail: "susana@virginislandsac.com"
    },
    {
        aboutId: "7",
        role: "Member",
        name: "Nicholas D'Amour",
        description: "Nicholas D'Amour, a recurve archer from the Virgin Islands, competed in archery at the 2020 Summer Olympics.",
        image: "nicholas",
        mail: "nicholas@virginislandsac.com"
    }
];

export default about;
