import React, { useState } from 'react';
import News from '../data/news';
import Events from '../data/events';
import Footer from '../components/Footer';
import aHeadline from '../assets/viac-news.svg';
import placeholder from '../assets/viac-placeholder.jpg';
import useAdjustBodyHeight from '../hooks/useAdjustBodyHeight';

const NewsPage = () => {

  // State to manage which section is visible
  const [isNewsVisible, setIsNewsVisible] = useState(true);

  useAdjustBodyHeight();

  return (
    <div className='content'>
      <div className='headline'>
        <img src={aHeadline} alt="Virgin Islands Athletes Commission Wordmark" />
      </div>
      <div className='toggleButtonMobile'>
        <div onClick={() => setIsNewsVisible(true)} className={isNewsVisible ? 'activeButtonStyle' : 'inactiveButtonStyle'}>
          News
        </div>
        <div onClick={() => setIsNewsVisible(false)} className={!isNewsVisible ? 'activeButtonStyle' : 'inactiveButtonStyle'}>
          Events
        </div>
      </div>
      <div className='toprowDesktop'>
        <div className='newsToprow'>news</div>
        <div className='eventsToprow'>Upcoming events</div>
      </div>
      <div className='container'>
        <div className='container-columns'>

          <div className='newsDesktop' >
            {News.map((item, index) => (
              <div key={index} className='newsItem'>
                {item.image ? (
                  <img className='newsImg' src={require(`../assets/images/news/${item.image}.jpg`)} alt={item.description} />
                ) : (
                  <img className='newsImg' src={placeholder} alt="Virgin Islands Athletes' Commission Blazon" />
                )}
                <div className='descriptionContainer'>
                  <div className='topContainer'>
                    <p className='note'>{item.date}</p>
                    <h3>{item.headline}</h3>
                  </div>
                  <div className='bottomContainer'>
                    <p className='text'>{item.description}</p>
                    {item.link && (
                      <a href={item.link} target="_blank" rel="noopener noreferrer" className="button">Read&nbsp;More</a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className='eventsDesktop'>
            {Events.map((item, index) => (
              <div key={index} className='eventsItem'>
                <div className='descriptionContainer'>

                  <div className='topContainer'>
                    <p className='note'>{item.date}</p>
                    <h3>{item.headline}</h3>
                  </div>

                  <div className='bottomContainer'>
                    <p className='text'>{item.description}</p>
                    <div className='buttonRow'>
                      {item.rsvp && (
                        <a href={item.rsvp} target="_blank" rel="noopener noreferrer" className="button">RSVP</a>
                      )}
                      {item.directions && (
                        <a href={item.directions} target="_blank" rel="noopener noreferrer" className="button">Get&nbsp;directions</a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

        </div>
        <div className='container-columns-mobile'>

          <div className='newsMobile' style={{ display: isNewsVisible ? 'block' : 'none' }}>
            {News.map((item, index) => (
              <div key={index} className='newsItem'>
                {item.image ? (
                  <img className='newsImg' src={require(`../assets/images/news/${item.image}.jpg`)} alt={item.description} />
                ) : (
                  <img className='newsImg' src={placeholder} alt="Virgin Islands Athletes' Commission Blazon" />
                )}
                <div className='descriptionContainer'>
                  <div className='topContainer'>
                    <p className='note'>{item.date}</p>
                    <h3>{item.headline}</h3>
                  </div>
                  <div className='bottomContainer'>
                    <p className='text'>{item.description}</p>
                    {item.link && (
                      <a href={item.link} target="_blank" rel="noopener noreferrer" className="button">Read More</a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>



          <div className='eventsMobile' style={{ display: !isNewsVisible ? 'block' : 'none' }} >
            {Events.map((item, index) => (
              <div key={index} className='eventsItem'>
                <div className='descriptionContainer'>

                  <div className='topContainer'>
                    <p className='note'>{item.date}</p>
                    <h3>{item.headline}</h3>
                  </div>

                  <div className='bottomContainer'>
                    <p className='text'>{item.description}</p>
                    <div className='buttonRow'>
                      {item.rsvp && (
                        <a href={item.rsvp} target="_blank" rel="noopener noreferrer" className="button">RSVP</a>
                      )}
                      {item.directions && (
                        <a href={item.directions} target="_blank" rel="noopener noreferrer" className="button">Get&nbsp;directions</a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>


        <Footer isFixed={false} />
      </div>
    </div>

  );
};

export default NewsPage;