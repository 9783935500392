import React, { useState, useEffect, useRef } from 'react';
import Lottie from 'react-lottie';
import animationData from '../assets/animations/animation-1.json';
import animationDataMobile from '../assets/animations/animation-m.json';
import { importImage, shuffleArray, imageNames } from '../data/imgMapper';
import OlympicRingsSVG from '../assets/olympic-rings.svg';
import Footer from '../components/Footer'; // Import the Footer componentd

const MainPage = () => {
  const [images, setImages] = useState([]);
  const [isAnimationPlayed, setIsAnimationPlayed] = useState(false);

  const lottieRef = useRef(null);

  const mobileOptions = {
    loop: false,
    autoplay: false,
    animationData: animationDataMobile,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    const shuffledImageNames = shuffleArray(imageNames);

    const importPromises = shuffledImageNames.map(imageName =>
      importImage('mainpage', imageName)
    );

    Promise.all(importPromises).then(shuffledImages => {
      setImages(shuffledImages);
    }).catch(error => {
      console.error('Failed to load images', error);
    });
  }, []);

  useEffect(() => {
    if (images.length > 0 && !isAnimationPlayed) {
      lottieRef.current.play();
      setIsAnimationPlayed(true);
    }
  }, [images, isAnimationPlayed]);

  return (
    <div className='main'>

        <div className='logo-wrapper'>
          <div className='lottie'>
            <Lottie
              options={defaultOptions}
              isStopped={false}
              isPaused={false}
              ref={lottieRef}
            />
          </div>
          <div className='lottie-mobile'>
            <Lottie
              options={mobileOptions}
              isStopped={false}
              isPaused={false}
              ref={lottieRef}
            />
          </div>
        </div>
        
        <div className='images-row'>
          {images.map((imageSrc, index) => (
            <div key={index} className="image-container">
              <img src={imageSrc} alt={`Sport ${index}`} />
            </div>
          ))}
        </div>

        <Footer isFixed={true} />

        <div className='olyrings'>
          <img src={OlympicRingsSVG} alt="Olympic Rings" />
        </div>    
    </div>
  );
}

export default MainPage;