// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import MainPage from './pages/MainPage';
import Overlay from './components/Overlay';
import './App.css';
import './styling/styles.scss';
import AthletesPage from './pages/AthletesPage';
import AchievementsPage from './pages/AchievementsPage';
import NewsPage from './pages/NewsPage';
import AboutPage from './pages/AboutPage';

function App() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <Router>
      <div className="App">
        <Header toggleMenu={toggleMenu} />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/athletes" element={<AthletesPage />} />
          <Route path="/achievements" element={<AchievementsPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
        {isMenuOpen && (
          <div className={`menu-overlay ${isMenuOpen ? 'open' : ''}`}>
            <Overlay toggleMenu={toggleMenu} />
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;
