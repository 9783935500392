// Importing athlete images
// import MichaelDizonBumann from '../assets/images/athletes/michael-dizon-bumann-swimming.jpg';
// import AliyahBoston from '../assets/images/athletes/aliyah-boston-basketball.jpg';
// import JoeDoe from '../assets/images/athletes/joe-doe-basketball.jpg';
// import LenaFencer from '../assets/images/athletes/lena-fencer-fencing.jpg';
// import MaximeExample from '../assets/images/athletes/maxime-example-basketball.jpg';
import NatalieKleemanDay from '../assets/images/athletes/natalie-kleeman-day-basketball.jpg';


//ATHLETICS
import MikaelaSmith from '../assets/images/athletes/mikaela-smith-athletics.jpg';
import CharliseMorris from '../assets/images/athletes/Charlise-Morris-athletics.jpg';
import NatalieKupiers from '../assets/images/athletes/natalie-kupiers-swimming.jpg';
import ThomasCriklair from '../assets/images/athletes/thomas-criklair-athletics.jpg';
import BridgetKleinMichaelJrMichaelKlein from '../assets/images/athletes/Bridget-Klein-Michael-Jr-Michael-Klein-athletics.jpg';
import JadenStapleton from '../assets/images/athletes/Jaden-Stapleton-athletics.jpg';
import JavanteHurst from '../assets/images/athletes/Javante-Hurst-athletics.jpg';
import JayvanBenjamin from '../assets/images/athletes/Jayvan-Benjamin-athletics.jpg';
import MaliqueSmith from '../assets/images/athletes/Malique-Smith-athletics.jpg';
import MicahDominiqueU17 from '../assets/images/athletes/Micah-Dominique-U17-athletics.jpg';
import MichaelDizonBumann from '../assets/images/athletes/Michael-Dizon-Bumann-athletics.jpg';
import MichelleSmith from '../assets/images/athletes/Michelle-Smith-athletics.jpg';
import NathanLangleyU20 from '../assets/images/athletes/Nathan-Langley-U20-athletics.jpg';
import EddieLovette from '../assets/images/athletes/Eddie-Lovette-athletics.jpg';
import OsazeWilliams from '../assets/images/athletes/Osaze-Williams-athletics.jpg';
import QuemaneBell from '../assets/images/athletes/Quemane-Bell-athletics.jpg';
import RachelConhoff from '../assets/images/athletes/Rachel-Conhoff-athletics.jpg';
import SophiaSwindell from '../assets/images/athletes/sophia-swindell-athletics.jpg';
import BridgetKlein from '../assets/images/athletes/Bridget-Klein-athletics.jpg';
import EduardoGarcia from '../assets/images/athletes/Eduardo-Garcia-athletics.jpg';


// import NicholasClaxton from '../assets/images/athletes/nicholas-calxton-basketball.jpg';
// import NicholasDAmour from '../assets/images/athletes/nicholas-d-amour-archery.jpg';
// import RachelCohoff from '../assets/images/athletes/rachel-cohoff-athletics.jpg';
import SusanaFornaris from '../assets/images/athletes/susana-fornaris-fencing.jpg';
import YashiraRhymer from '../assets/images/athletes/yashira-rhymer-athletics.jpg';
// Placeholder for additional US Virgin Islands athletes
const athletesData = [
  {
    discipline: "Basketball",
    athletes: [
      {
        name: "Natalie Kleemann-Day",
        photo: NatalieKleemanDay,
      },
      // {
      //   name: "Joe Doe",
      //   photo: JoeDoe,
      // },
      // {
      //   name: "Maxime Example",
      //   photo: MaximeExample,
      // },
      // {
      //   name: "Name Surname",
      //   // photo: NicholasClaxton,
      // },
      // {
      //   name: "Name Surname",
      //   // photo: AliyahBoston,
      // }
    ]
  },
  {
    discipline: "Swimming",
    athletes: [
      // {
      //   name: "Example Swimmer",
      //   // photo: MichaelDizonBumann,
      // },
      {
        name: "Natalie Kuipers",
        photo: NatalieKupiers,
      }
    ]
  },

  {
    discipline: "Fencing",
    athletes: [
      // {
      //   name: "Lena Fencer",
      //   photo: LenaFencer,
      // },
      {
        name: "Susana Fornaris",
        photo: SusanaFornaris,
      }
    ]
  },
  {
    discipline: "Athletics",
    athletes: [
      {
        name: "Mikaela Smith",
        photo: MikaelaSmith,
      },
      {
        name: "Yashira Rhymer",
        photo: YashiraRhymer,
      },
      {
        name: "Charlise Morris",
        photo: CharliseMorris,
      },
      {
        name: "Eddie Lovette",
        photo: EddieLovette,
      },
      {
        name: "Malique Smith",
        photo: MaliqueSmith,
      },

      {
        name: "Sophia Swindell",
        photo: SophiaSwindell,
      },
      {
        name: "Michael Dizon Bumann",
        photo: MichaelDizonBumann,
      },
      {
        name: "Michelle Smith",
        photo: MichelleSmith,
      },
      {
        name: "Osaze Williams",
        photo: OsazeWilliams,
      },

      {
        name: "Micah Dominique",
        photo: MicahDominiqueU17,
      },

      {
        name: "Eduardo Garcia",
        photo: EduardoGarcia,
      },
      {
        name: "Quemane Bell",
        photo: QuemaneBell,
      },
     
      {
        name: "Rachel Conhoff",
        photo: RachelConhoff,
      },
      {
        name: "Nathan Langley",
        photo: NathanLangleyU20,
      },

      {
        name: "Jaden Stapleton",
        photo: JadenStapleton,
      },
    
      {
        name: "Bridget Klein",
        photo: BridgetKlein,
      },
      {
        name: "Javante Hurst",
        photo: JavanteHurst,
      },
      {
        name: "Bridget Klein, Michael Jr., and Michael Klein",
        photo: BridgetKleinMichaelJrMichaelKlein,
      },
      {
        name: "Jayvan Benjamin",
        photo: JayvanBenjamin,
      },
      {
        name: "Thomas Criklair",
        photo: ThomasCriklair,
      },
      
    ]
  },
  {
    discipline: "Archery",
    athletes: [
      {
        name: "Nicholas D'Amour",
        // photo: NicholasDAmour,
      }
    ]
  }
  // Add additional disciplines and athletes as needed
];

export default athletesData;